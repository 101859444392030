export const cardImageSizes = {
  mobile: {
    width: 80,
    height: 140,
  },
  desktop: {
    width: 210,
    height: 192,
  },
  productDetailsPage: {
    width: 340,
    height: 340,
  },
  recommendations: {
    width: 230,
    height: 230,
  },
} as const;

export const getCardImageUrl = (
  src: string | undefined,
  device: 'mobile' | 'desktop' | 'productDetailsPage' | 'recommendations',
): string => {
  if (!src) {
    return '';
  }

  const { width, height } = cardImageSizes[device];

  const url = new URL(src);

  // https://docs.imgix.com/apis/url
  // Apply these params to the image URL
  Object.entries({
    fm: 'avif',
    fit: 'fill',
    fill: 'solid',
    'fill-color': '00FFFFFF',
    trim: 'color',

    // Lock in the aspect ratio, as this is a fixed size image, but the `imgixLoader`
    // doesn't take a height param so we need to set it here
    ar: `${width}:${height}`,
  }).map(([key, value]) => url.searchParams.set(key, value));

  return url.toString();
};
