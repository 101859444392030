import { ImageLoaderProps } from 'next/image';

// https://nextjs.org/docs/app/api-reference/next-config-js/images#imgix
export const imgixLoader = ({ src, width, quality }: ImageLoaderProps): string => {
  if (!src) {
    return '';
  }

  try {
    const url = new URL(src);
    const params = url.searchParams;
    params.set('auto', params.getAll('auto').join(',') || 'format');
    params.set('w', params.get('w') || width.toString());
    params.set('q', params.get('q') || (quality || 80).toString());

    return url.href;
  } catch (error) {
    return src;
  }
};
