/**
 * Removes duplicate elements from an array and returns a new array with unique elements.
 *
 * @template T - The type of elements in the array.
 * @param array - The array from which to remove duplicate elements.
 * @returns A new array with unique elements.
 */
export const unique = <T extends string | number>(array: T[]): T[] => {
  return Array.from(new Set(array));
};

type Comparator<T> = (a: T, b: T) => number;

// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore/issues/280#issuecomment-690604745
const sortBy = <T>(key: keyof T, cb?: Comparator<T>): Comparator<T> => {
  if (!cb) {
    cb = () => 0;
  }
  return (a, b) =>
    a[key] > b[key]
      ? 1
      : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        b[key] > a[key]
        ? -1
        : cb!(a, b);
};

// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore/issues/280#issuecomment-690604745
const sortByDesc = <T>(key: keyof T, cb?: Comparator<T>): Comparator<T> => {
  if (!cb) {
    cb = () => 0;
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return (b, a) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : cb!(b, a));
};

/**
 * Creates a comparator function for ordering an array of objects based on the specified keys and
 * orders.
 *
 * https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore/issues/280#issuecomment-690604745
 *
 * @template T - The type of the objects in the array.
 * @param {(keyof T)[]} keys - An array of keys to order the objects by.
 * @param {('asc' | 'desc')[]} orders - An array of orders corresponding to the keys.
 * @returns {Comparator<T>} - A comparator function that can be used to sort an array of objects.
 * @throws {Error} - If an unsupported order is provided.
 */
export const orderBy = <T>(keys: (keyof T)[], orders: ('asc' | 'desc')[]): Comparator<T> => {
  let cb: Comparator<T> = () => 0;
  keys.reverse();
  orders.reverse();
  for (const [i, key] of keys.entries()) {
    const order = orders[i];
    if (order === 'asc') {
      cb = sortBy(key, cb);
    } else if (order === 'desc') {
      cb = sortByDesc(key, cb);
    } else {
      throw new Error(`Unsupported order "${order}"`);
    }
  }
  return cb;
};

/**
 * Returns a random element from the given array.
 *
 * @template T - The type of elements in the array.
 * @param {T[]} arr - The array from which to select a random element.
 * @returns {T} - A random element from the array.
 */
export const sample = <T>(arr: T[]): T => {
  return arr[Math.floor(Math.random() * arr.length)];
};

/**
 * Sorts an array of payment plans by their term length.
 *
 * @template T - The type of the payment plan objects, which must include a `termLength` property.
 * @param {T[]} paymentPlans - The array of payment plans to be sorted.
 * @param {'asc' | 'desc'} order - The order in which to sort the payment plans. Use 'asc' for
 *   ascending order and 'desc' for descending order.
 * @returns {T[]} - The sorted array of payment plans.
 */
export const sortPaymentPlansByTermLength = <T extends { termLength: number }>(
  paymentPlans: T[],
  order: 'asc' | 'desc',
): T[] => {
  return paymentPlans
    ? [...paymentPlans].sort((a, b) => {
        if (order === 'asc') {
          return a.termLength - b.termLength;
        }
        return b.termLength - a.termLength;
      })
    : [];
};
