import * as React from 'react';

function PreApprovedIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1.2em" height="1.2em" viewBox="0 0 14 15" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.862.928a.966.966 0 00-.335.266L5.632 2.3a.967.967 0 01-.857.356l-1.421-.155A.964.964 0 002.287 3.57l.155 1.422a.965.965 0 01-.355.857l-1.114.896a.965.965 0 000 1.505l1.114.896a.966.966 0 01.355.857l-.155 1.422a.965.965 0 001.067 1.067l1.421-.155a.964.964 0 01.857.356l.895 1.114a.964.964 0 001.504 0l.892-1.11a.965.965 0 01.857-.356l1.421.155a.965.965 0 001.066-1.067l-.154-1.422a.968.968 0 01.355-.858l1.118-.892a.966.966 0 000-1.505l-1.114-.896a.966.966 0 01-.356-.857l.155-1.422a.967.967 0 00-1.066-1.067l-1.422.155a.964.964 0 01-.856-.356L8.03 1.194A.965.965 0 006.862.928zM10.1 6.617a.75.75 0 10-1.2-.9L6.754 8.58 5.588 7.414a.75.75 0 00-1.06 1.06L5.943 9.89a1.193 1.193 0 001.8-.128L10.1 6.617z"
      />
    </svg>
  );
}

const MemoPreApprovedIcon = React.memo(PreApprovedIcon);
export default MemoPreApprovedIcon;
