import { useAppContext } from '../../hooks/useAppContext';
import PreApprovedAmountBanner from './PreApprovedAmountBanner';
import { StyledPreApprovedHeaderBanner, StyledPreApprovedHeaderBannerContent } from './styles';
import { px2Rem } from '../../utils/px2Rem';
import Icon from '../../elements/Icon';
import { palette } from 'uibook';

const PreApprovedHeaderBanner = ({ formattedAmount }: { formattedAmount: string }) => {
  const { setModalOpen } = useAppContext();

  return (
    <StyledPreApprovedHeaderBanner>
      <StyledPreApprovedHeaderBannerContent onClick={() => setModalOpen('aboutYourLimit')}>
        <PreApprovedAmountBanner formattedAmount={formattedAmount} />
        <Icon name="Info" fill={palette.charcoal[400]} size={px2Rem(16)} />
      </StyledPreApprovedHeaderBannerContent>
    </StyledPreApprovedHeaderBanner>
  );
};

export default PreApprovedHeaderBanner;
