import { VariantForPLP } from '../types/productTypes';

export const updateEligible = (products: VariantForPLP[], addTechAllowance: number) => {
  return products.map((product) => {
    if (!product.lowestMonthlyCost) {
      return product;
    }

    const eligible = product.lowestMonthlyCost <= addTechAllowance;

    return {
      ...product,
      eligible,
    };
  });
};
