import { contentColor, StyledBadge, StyledBadgeText, StyledBadgeWrap } from './Badges.styles';
import { BadgeProps } from './Badge.types';
import React from 'react';
import PreApprovedIcon from './PreApprovedIcon';

export const Badge = ({
  type,
  color,
  icon,
  fontSize,
  fontWeight,
  textTransform,
  children,
  dataTestId,
}: BadgeProps) => {
  return (
    <div style={{ position: 'relative' }} data-testid={dataTestId}>
      <StyledBadge
        $fontWeight={fontWeight}
        $type={type}
        $color={color}
        $fontSize={fontSize}
        $textTransform={textTransform}
      >
        {icon && <PreApprovedIcon data-testid="badge-icon" fill={contentColor(color)} />}
        <StyledBadgeText $textTransform={textTransform}>{children}</StyledBadgeText>
      </StyledBadge>
      {type === 'wrapped' && <StyledBadgeWrap data-testid="badge-wrap" $color={color} />}
    </div>
  );
};
