import { Link } from '@/elements/Link/Link';
import { BreadcrumbItem } from './types';
import { ASSETS_SVGS_BASE_URL, cn } from 'uibook';
import { useAppContext } from '@/hooks/useAppContext';
import { useRouter } from 'next/router';

const home = { name: 'Home', url: 'https://raylo.com' };

export const Breadcrumbs = ({
  items,
  showBackButton,
}: {
  items: BreadcrumbItem[];
  showBackButton?: boolean;
}) => {
  const { previousPageIsProducts } = useAppContext();
  const router = useRouter();

  const linkClassName =
    'hover:text-blue-200 hover:underline flex cursor-pointer items-center type-body2';
  const chevronClassName = 'h-3 w-3 inline-block m-0 mx-2';
  const wrapperClassName =
    'min-[333px]:pt-[142]px flex w-full items-center overflow-x-scroll bg-blue-500 px-6 pb-6 pt-[159px] text-sm leading-5 text-white min-[560px]:pt-[124px] lg:pt-[173px]';

  return (
    <div data-testid="breadcrumbs" className={wrapperClassName}>
      {previousPageIsProducts && showBackButton && (
        <button
          className="type-body2 flex cursor-pointer items-center text-white hover:text-blue-200 hover:underline"
          onClick={() => router.back()}
          data-testid="breadcrumb-back-button"
        >
          <img src={`${ASSETS_SVGS_BASE_URL}/back-arrow-white.svg`} alt="Back" className="mr-2" />
          Back
          <div className="mx-2 h-4 w-px bg-blue-400" />
        </button>
      )}
      <a
        className={linkClassName}
        href={home.url}
        title={home.name}
        data-testid={`breadcrumb-link-${home.name}`}
      >
        {home.name}
        <img
          className={chevronClassName}
          src={`${ASSETS_SVGS_BASE_URL}/chevron-right-white.svg`}
          alt="chevron right"
        />
      </a>
      {items.map(({ name, url, disabled }, index) => (
        <Link
          data-testid={`breadcrumb-link-${name}`}
          key={index}
          href={url}
          title={name}
          className={cn(linkClassName, {
            'pointer-events-none text-pink-300': disabled,
            'text-white': !disabled,
          })}
        >
          {name}
          <img
            className={cn(chevronClassName, { hidden: index === items.length - 1 })}
            src={`${ASSETS_SVGS_BASE_URL}/chevron-right-white.svg`}
            alt="chevron right"
          />
        </Link>
      ))}
    </div>
  );
};
