import { gql } from '../generated';

export const CUSTOMER_SPECIFIC_PRICING = gql(`
  query ProductsRepoCustomerSpecificPricing($ids: [ID!], $merchantId: ID!, $checkoutToken: String) {
    variants(forCheckout: true, ids: $ids, checkoutToken: $checkoutToken, merchantId: $merchantId) {
      id
      costSummaries(merchantId: $merchantId) {
        id
        recurring {
          ...VariantCostSummaryRecurringFields
        }
      }
    }
  }
`);

export const CUSTOMER_SPECIFIC_LOWEST_COST_SUMMARY = gql(`
  query ProductsRepoCustomerSpecificLowestCostSummary($ids: [ID!], $merchantId: ID!, $checkoutToken: String) {
    variants(forCheckout: true, ids: $ids, checkoutToken: $checkoutToken, merchantId: $merchantId) {
      id
      lowestCostSummary(merchantId: $merchantId) {
        id
        recurring {
          ...VariantCostSummaryRecurringFields
        }
      }
    }
  }
`);
