import { Typography } from '../../foundations/Typography/Typography';
import { cn } from '../../utils/cn';

export const LoginPromptBanner = ({
  classNames,
  accountBaseUrl,
  dataTestId,
}: {
  classNames: string;
  accountBaseUrl: string;
  dataTestId?: string;
}) => (
  <div
    className={cn('border-charcoal-200 flex-wrap justify-center border-b px-6 py-2.5', classNames)}
    data-testid={dataTestId}
  >
    <Typography variant="body2" className="text-charcoal-500" medium>
      Existing customer?&nbsp;
    </Typography>
    <Typography
      className="text-blue-500 hover:underline"
      medium
      element="a"
      variant="body2"
      href={`${accountBaseUrl}/account`}
    >
      Log in to add more tech or upgrade
    </Typography>
  </div>
);
