import { px2Rem } from './../../utils/px2Rem';
import styled from 'styled-components';
import { IStyledSpan } from './StyledSpan.types';
import { CSS_FONT_WEIGHT } from '../../constants';

const StyledStyledSpan = styled.span<IStyledSpan>`
  ${({ color }) => color && `color: ${color}`};
  ${({ boldFont }) => boldFont && `font-weight: ${CSS_FONT_WEIGHT.vars.bold}`};
  ${({ mediumFont }) => mediumFont && `font-weight: ${CSS_FONT_WEIGHT.vars.medium}`};
  ${({ regularFont }) => regularFont && `font-weight: ${CSS_FONT_WEIGHT.vars.regular}`};
  ${({ fontSize }) => fontSize && `font-size: ${px2Rem(fontSize)}`};
  ${({ lineHeight }) => lineHeight && `line-height: ${px2Rem(lineHeight)}`};
  ${({ underline }) => underline && 'text-decoration: underline; cursor: pointer;'};
`;

export { StyledStyledSpan };
