import PreApprovedAmountBanner from './PreApprovedAmountBanner';
import PreApprovedHeaderBanner from './PreApprovedHeaderBanner';
import AboutYourLimitModal from './AboutYourLimitModal';
import NotApprovedDeviceModal from './NotApprovedDeviceModal';
import IneligiblePaymentBox from './IneligiblePaymentBox';

export {
  PreApprovedAmountBanner,
  PreApprovedHeaderBanner,
  AboutYourLimitModal,
  NotApprovedDeviceModal,
  IneligiblePaymentBox,
};
