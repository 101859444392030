import { useEffect, useState, useMemo } from 'react';
import constate from 'constate';
import {
  filterProducts,
  sortProductItems,
  ALL_SORT_OPTIONS,
  trackToggledFilters,
  trackClearAllFilters,
  trackSortOptionSelected,
} from '../components/ProductFilter/utils';
import { useRouter } from 'next/router';
import queryString from 'query-string';
import { dequal } from 'dequal';
import { useLazyQuery } from '@apollo/client';
import { CUSTOMER_SPECIFIC_LOWEST_COST_SUMMARY } from '@/graphql/operations/customerSpecificPricing.graqhql';
import { useCustomerContext } from './useCustomerContext';
import { updateCostSummaries } from '../utils/updateCostSummaries';
import { captureException } from '@sentry/core';
import { updateEligible } from '../utils/updateEligible';
import { VariantForPLP } from '../types/productTypes';
import { useConsumerTypeContext } from './useConsumerTypeContext';

export type SortType = 'Recommended' | 'Highest Price' | 'Lowest Price';
export type SortDirection = 'asc' | 'desc';
export type SortKey = 'lowestMonthlyCost' | 'position' | 'eligible';

export type SortOption = {
  label: SortType;
  value: SortType;
};

export type FilterState = {
  category: (string | null)[];
  make: (string | null)[];
  condition: (string | null)[];
  model: (string | null)[];
  monthlyprice: (string | null)[];
};

const emptyFilterState = {
  category: [],
  make: [],
  condition: [],
  model: [],
  monthlyprice: [],
};

const getInitialState = (URLParams: string | (string | null)[] | null) => {
  if (!URLParams) return [];

  if (typeof URLParams === 'string') {
    return [URLParams];
  }

  return URLParams.filter((param) => param !== null);
};

const useProducts = ({ productData }: { productData: VariantForPLP[] }) => {
  const router = useRouter();
  const { hasLoggedInCustomer, checkoutToken, preApprovedAmount } = useCustomerContext();
  const { consumerMoneyField } = useConsumerTypeContext();
  /**
   * Using a hard-coded `https://www.raylo.com` might not be the worst thing here. We need an
   * absolute URL to get the `search` params, but this feels safer than hand-writing a function. By
   * using `useRouter`, we can get the `asPath` on the server-side, which means we don't need to
   * wait for the client to load, and use the `window` object. Unfortunately, we can't get
   * `router.query` on the server-side, which is why we can convert the absolute URL to a `URL`
   * object, and extract the `search` params from that.
   */
  const initialUrl = new URL(`https://www.raylo.com${router.asPath}`);
  const params = queryString.parse(initialUrl.search, { arrayFormat: 'none' });
  const [shouldDisplayPrices, setShouldDisplayPrices] = useState<boolean>(false);

  const initialFilterState = useMemo(
    () => ({
      category: getInitialState(params?.category),
      make: getInitialState(params?.make),
      condition: getInitialState(params?.condition),
      model: getInitialState(params?.model),
      monthlyprice: getInitialState(params?.monthlyprice),
    }),
    [params?.category, params?.condition, params?.make, params?.model, params?.monthlyprice],
  );

  const [
    getCustomerSpecificPricing,
    { data: customerSpecificPricing, loading: customerSpecificPricingLoading },
  ] = useLazyQuery(CUSTOMER_SPECIFIC_LOWEST_COST_SUMMARY);

  const availableProducts = useMemo(() => {
    let products = productData;

    if (customerSpecificPricing?.variants) {
      products = updateCostSummaries(
        products,
        customerSpecificPricing.variants,
        consumerMoneyField,
      );
      if (preApprovedAmount) {
        products = updateEligible(products, preApprovedAmount);
      }
    }

    return products.filter(({ available }) => available);
  }, [productData, customerSpecificPricing, preApprovedAmount, consumerMoneyField]);

  useEffect(() => {
    if (
      hasLoggedInCustomer &&
      availableProducts.length &&
      !customerSpecificPricing?.variants &&
      !customerSpecificPricingLoading
    ) {
      getCustomerSpecificPricing({
        variables: {
          ids: availableProducts.map(({ variantId }) => variantId),
          merchantId: process.env.NEXT_PUBLIC_RAYLO_MERCHANT_ID,
          checkoutToken: checkoutToken ?? null,
        },
        onError: (error) => {
          console.error('PRICING ERROR PRODUCTS', error);
          captureException(error);
        },
      });
    }

    if (hasLoggedInCustomer === false) {
      setShouldDisplayPrices(true);
    }
  }, [
    hasLoggedInCustomer,
    getCustomerSpecificPricing,
    availableProducts,
    customerSpecificPricing,
    checkoutToken,
    customerSpecificPricingLoading,
  ]);

  useEffect(() => {
    if (customerSpecificPricing?.variants?.length) {
      setShouldDisplayPrices(true);
    }
  }, [customerSpecificPricing]);

  const [filteredProducts, setFilteredProducts] = useState(
    filterProducts(availableProducts, initialFilterState),
  );
  const [filters, setFilters] = useState<FilterState>(initialFilterState);
  const [activeSortLabel, setActiveSortLabel] = useState<SortOption>(ALL_SORT_OPTIONS[0]);
  const [showResultsButton, setShowResultsButton] = useState<boolean>(false);

  useEffect(() => {
    const filtered = filterProducts(availableProducts, filters);
    const sorted = sortProductItems(filtered, activeSortLabel.value);
    setFilteredProducts(sorted);

    if (!dequal(filters, emptyFilterState)) {
      setShowResultsButton(true);
    }
  }, [filters, activeSortLabel.value, availableProducts]);

  useEffect(() => {
    if (params?.category) {
      setFilters((oldFilters) => {
        const data = Array.isArray(params.category) ? params.category : [params.category];

        return {
          ...oldFilters,
          category: data,
        };
      });
    }

    if (['/products', '/business/products'].includes(router.asPath)) {
      setFilters(emptyFilterState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router?.asPath]);

  const hostname = typeof window !== 'undefined' ? window?.location?.hostname : '';
  const merchant = hostname?.includes('raylopay') ? hostname : 'raylo';

  const toggleFilters = (filterType: keyof FilterState, value: string) => {
    setFilters((oldFilters) => {
      const filterCategory = (
        oldFilters.category.length === 1 ? oldFilters.category[0] : 'All'
      ) as string;

      if (value === 'All') {
        delete params[filterType];
        router.replace({
          search: queryString.stringify(params)?.toLowerCase(),
        });

        trackToggledFilters(filterCategory, filterType, value, oldFilters, merchant);

        return {
          ...oldFilters,
          [filterType]: [],
        };
      }

      const items = oldFilters[filterType];
      const newFilters = items.map((item) => item?.toLowerCase()).includes(value.toLowerCase())
        ? items.filter((item) => item?.toLowerCase() !== value.toLowerCase())
        : [...items, value];

      params[filterType] = newFilters;
      router.replace({
        search: queryString.stringify(params)?.toLowerCase(),
      });

      trackToggledFilters(filterCategory, filterType, value, oldFilters, merchant);

      return {
        ...oldFilters,
        [filterType]: newFilters,
      };
    });
  };

  const resetFilter = () => {
    setFilters(emptyFilterState);

    router.replace({
      search: '',
    });

    trackClearAllFilters(merchant);
  };

  const sortFilteredProducts = (sortType: SortType) => {
    setFilteredProducts((oldProducts) => sortProductItems(oldProducts, sortType));
  };

  const handleSortProductsChange = (option: SortOption) => {
    sortFilteredProducts(option.value);
    setActiveSortLabel(option);
    setShowResultsButton(true);
    trackSortOptionSelected(option.value);
  };

  return {
    availableProducts,
    filteredProducts,
    filters,
    toggleFilters,
    resetFilter,
    handleSortProductsChange,
    activeSortLabel,
    showResultsButton,
    setShowResultsButton,
    shouldDisplayPrices,
  };
};

const [ProductsProvider, useProductsContext] = constate(useProducts);
export { ProductsProvider, useProductsContext };
