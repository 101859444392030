import NEW_BREAKPOINTS from '@/constants/breakpoints';
import styled from 'styled-components';
import { px2Rem } from '../../utils/px2Rem';
import { palette } from 'uibook';

const StyledPreApprovedHeaderBanner = styled.div`
  width: 100%;
  background-color: ${palette.white};
  padding: ${px2Rem(16)} ${px2Rem(24)};
`;

const StyledPreApprovedHeaderBannerContent = styled.button`
  appearance: none;
  background: none;
  border: 0;
  padding: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${px2Rem(8)};
  cursor: pointer;

  @media (min-width: ${NEW_BREAKPOINTS.tablet}px) {
    width: auto;
    justify-content: flex-start;
  }
`;

const StyledIneligiblePaymentBox = styled.div<{
  dataTestId?: string;
  borderColor?: string;
}>`
  height: ${px2Rem(85)};
  border: ${px2Rem(1)} solid ${palette.charcoal[200]};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${px2Rem(14)};
  border-radius: ${px2Rem(4)};
  color: ${palette.charcoal[500]};
  background-color: ${palette.charcoal[100]};

  & h6 {
    padding: 0;
    margin: 0;
  }
`;

export {
  StyledPreApprovedHeaderBanner,
  StyledPreApprovedHeaderBannerContent,
  StyledIneligiblePaymentBox,
};
