import React from 'react';
import { StyledStyledSpan } from './StyledSpan.styles';
import { IStyledSpan } from './StyledSpan.types';

export const StyledSpan = ({
  dataTestId,
  children,
  mediumFont,
  boldFont,
  regularFont,
  fontSize,
  color,
  onClick = () => {},
  className,
  lineHeight,
  underline,
}: IStyledSpan) => {
  return (
    <StyledStyledSpan
      className={className}
      data-testid={dataTestId}
      fontSize={fontSize}
      mediumFont={mediumFont}
      boldFont={boldFont}
      regularFont={regularFont}
      color={color}
      lineHeight={lineHeight}
      onClick={onClick}
      underline={underline}
    >
      {children}
    </StyledStyledSpan>
  );
};
